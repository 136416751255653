import React from 'react'

import { Footer } from '../common/footer'
import { Navigation } from '../common/navigation'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { CMSWrapper } from '../components/cmsWrapper'
import { StaticReferenceModules } from '../components/references/staticReferenceModules'
import { StationsShortlist } from '../components/stations/stationsShortlist'
import { graphql } from 'gatsby'
import { AREA_MTRA_PATH, AREA_MTRA } from '../config/constants'
import { Button } from '../common/button'

export default function Index({ pageContext, data }) {
  const areaPageReferences = data.allReference.nodes
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - MTR/MTRA" />
      <main>
        <Navigation />
        <header className="bg-img-yellow divider-left header--static">
          <div className="row align-items-center">
            <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
              <img
                src="/img/handlettering/handlettering-unsichtbares-sichtbar-machen.svg"
                alt="Handlettering: Unsichtbares sichtbar machen"
                title="Unsichtbares sichtbar machen"
                width="370"
              />
            </div>

            <div className="col-md-5 offset-md-0 col-10 offset-1 text-center">
              <img
                src="/img/mtra/illustration-mtra.png"
                alt="Illustration: MTRA"
              />
            </div>

            <div className="col-md-10 col-10 offset-1 text-center mt-4">
              <h1>Medizinische:r Technolog:in für Radiologie (MTR / MTRA)</h1>
              <h2>
                Dein Durchblick ist gefragt! Du bist die Schnittstelle zwischen
                Mensch und Technik und machst sichtbar, was den Menschen in
                seinem Inneren zusammenhält – oder krank macht.
              </h2>
            </div>
          </div>
        </header>

        <section id="matching-stats">
          <div id="stats" className="row align-items-center text-center">
            <div className="col-md-4 col-12">
              <h3>Unser Bereich in Zahlen:</h3>
            </div>
            <div className="col-md-4 col-6">
              <figure>
                <img
                  src="/img/icons/icon-team.png"
                  height="80"
                  width="80"
                  alt="Team"
                  loading="lazy"
                />
                <figcaption>
                  <h4>
                    MTRs im UKE <br />
                    <span>&gt;100</span>
                  </h4>
                </figcaption>
              </figure>
            </div>
            <div className="col-md-4 col-6">
              <figure>
                <img
                  src="/img/icons/icon-geraet.png"
                  height="80"
                  width="80"
                  alt="Radiologische Geräte"
                  loading="lazy"
                />
                <figcaption>
                  <h4>
                    Radiologische Geräte <br />
                    <span>&gt;120</span>
                  </h4>
                </figcaption>
              </figure>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>
                Ohne dich keine Diagnose, und ohne Diagnose keine Therapie!
              </h2>
              <p>
                Mit Fingerspitzengefühl, Empathie und Expertise bringst du deine
                Patient:innen in die passende Lage für optimale Bilder. Diese
                sind die Basis für eine gesicherte ärztliche Diagnostik, rund um
                die Uhr, an 365 Tagen im Jahr.
              </p>
              <p>
                Bei uns findest du das gesamte Spektrum einer MTR/MTRA: vom
                konventionellen und pädiatrischen Röntgen, über CT, MRT,
                Nuklearmedizin, Angiographie bis zur neuroradiologischen
                Diagnostik. <strong>Hightech ist unser Standard</strong>.
              </p>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mtra/BSS_MTRA_Bild_1.jpg"
                    alt="BSS MTRA Bild 1"
                    title="BSS MTRA Bild 1"
                    loading="lazy"
                  />
                </div>
              </div>
              <p>
                In unserem Zentrum für Radiologie und Endoskopie versorgst du
                stationäre und ambulante Patient:innen mit der benötigten
                Bildgebung und unterstützt bei Eingriffen mit bildgebenden
                Verfahren.
              </p>
              <p>
                Magst du Abwechslung und willst dich verändern? Dann freue dich
                schon jetzt auf eine verantwortungsvolle und vielseitige
                Tätigkeit bei uns im UKE. Durch das Rotationsprinzip in deiner
                Abteilung ist kein Tag wie der andere.
              </p>
              <p>
                Du kannst in kleinen oder großen Teams (5 bis 50 MTRs/MTRAs)
                arbeiten und ggf. an der Erforschung neuer Behandlungsmethoden
                mitwirken.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>
                Und du hast die Wahl – stöbere dich durch unsere
                MTR/MTRA-Bereiche!
              </h2>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static my-4">
                  <img
                    src="/img/mtra/BSS_MTRA_Bild_2.jpg"
                    alt="BSS MTRA Bild 2"
                    title="BSS MTRA Bild 2"
                    loading="lazy"
                  />
                </div>
              </div>
              <p>
                <strong>
                  Unsicher, welcher Bereich der Richtige für dich ist?
                </strong>{' '}
                Kein Problem, das kannst du unkompliziert über eine Hospitation
                herausfinden. Melde dich direkt in deinem Wunschbereich oder
                kontaktiere Rita Herbrand über den Kontakt am Ende dieser Seite.
              </p>
              <p>
                <strong>
                  Sicher ist: Wir suchen Verstärkung – und wollen dich!
                </strong>
              </p>
              <p>
                Ob Berufsanfänger:in, Wiedereinsteiger:in oder erfahrene/r
                MTR/MTRA: Wir haben die{' '}
                <a href="https://www.uke.jobs/index.php?ac=search_result&search_criterion_keyword%5B%5D=MTR&search_criterion_activity_level%5B%5D=5">
                  passende Stelle
                </a>{' '}
                für dich im UKE.
              </p>
              <div className="text-center">
                <Button
                  color="yellow"
                  href={`${AREA_MTRA_PATH}/stationen`}
                  className="my-2"
                >
                  Stöbern
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Werde Teil unseres MTR/MTRA-Teams!</h2>
              <p>
                <strong>Das hast du davon:</strong>
              </p>
              <ul>
                <li>
                  Attraktiver Arbeitgeber: Bei uns findest du einen sicheren
                  Arbeitsplatz, kannst dich entwickeln und über dich
                  hinauswachsen
                </li>
                <li>
                  Exzellenz: Das UKE zählt zu den modernsten Kliniken Europas,
                  in der sich Spitzenmedizin, Forschung und Lehre vernetzen; bei
                  neuen Untersuchungs- und Therapieverfahren bist du stets am
                  Puls der Zeit
                </li>
                <li>
                  Spannender Arbeitsalltag, indem du Wertschätzung und
                  Unterstützung erfährst und dich voll einbringen kannst
                </li>
                <li>
                  Faire Bezahlung analog zum Tarifvertrag des Öffentlichen
                  Dienstes; weitere Vergütungsbestandteile; 30 Tage Urlaub im
                  Jahr
                </li>
                <li>Betriebliche Altersversorgung</li>
                <li>
                  Familienfreundlichkeit: Das UKE ist preisgekrönt für die
                  Vereinbarkeit von Familie, Beruf und Freizeit (kostenlose
                  Ferienbetreuung, UKE-eigene KiTa mit schicht-kompatiblen
                  Betreuungszeiten)
                </li>
                <li>
                  Zahlreiche Gesundheits- und Präventionsangebote wie
                  Akupunktur, Outdoor-Training, Segeln, Yoga, etc.
                </li>
                <li>
                  Zuschuss zum HVV-ProfiTicket, Dr. Bike Fahrradservice und
                  Fahrradleasing-Angebote
                </li>
                <li>Unterstützung bei der Wohnungssuche</li>
                <li>
                  Umzugspauschale: bis zu 2.000 Euro für die anfallenden Kosten,
                  wenn ein Umzug (von außerhalb des HVV-Gesamtbereichs) nach
                  Hamburg erforderlich ist. Sprich dieses Thema am besten gleich
                  beim Bewerbungsgespräch an
                </li>
                <li>Individuelle & intensive Einarbeitung</li>
              </ul>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mtra/BSS_MTRA_Bild_3.jpg"
                    alt="BSS MTRA Bild 3"
                    title="BSS MTRA Bild 3"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Mach dir keinen Kopf!</h2>
              <p>
                Du denkst, das UKE sei eine Nummer zu groß für dich? Keine
                Sorge, denn bist du nie allein. Dein Team, deine Kolleg:innen
                stehen dir bei Fragen immer zur Seite.
              </p>
              <p>
                Außerdem gilt: Wenn es für dich angenehmer ist, kannst du auch
                erst in einem kleineren Team starten und dir das Gesamte
                langsamer erschließen.{' '}
                <strong>
                  Alles ist machbar - so wie es für dich am besten ist!
                </strong>
              </p>
              <p>
                <strong>
                  Ob Patient:in oder Mitarbeiter:in – bei uns steht der Mensch
                  im Mittelpunkt.
                </strong>
              </p>
              <p>
                Wir passen aufeinander auf und sorgen dafür, dass du einen guten
                Start hast.
              </p>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mtra/BSS_MTRA_Bild_4.jpg"
                    alt="BSS MTRA Bild 4"
                    title="BSS MTRA Bild 4"
                    loading="lazy"
                  />
                </div>
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mtra/BSS_MTRA_Bild_5.jpg"
                    alt="BSS MTRA Bild 5"
                    title="BSS MTRA Bild 5"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Deine Einarbeitung: maßgeschneidert</h2>
              <h3>UKE-Einführungstage</h3>
              <p>
                Gemeinsam mit anderen neuen Mitarbeiter:innen lernst du als
                erstes berufsübergreifend 2 bis 5 Tage (je nach Einsatzbereich)
                das UKE kennen. Dabei erfährst du alles Wichtige und
                Wissenswerte rund um deinen neuen Arbeitgeber.
              </p>
              <h3>Einarbeitung vor Ort</h3>
              <p>
                Anschließend erhältst du eine bereichsspezifische Einarbeitung
                in deinem Arbeitsbereich. In der Anfangsphase stehen dir
                ausgewählte Kolleg:innen als Partner:innen zur Seite, denen du
                über die Schulter schaust.{' '}
              </p>
              <h3>Gut ankommen</h3>
              <p>
                Neu in der Hansestadt? Deine Kolleg:innen haben garantiert heiße
                Tipps parat, was-wann-wo in Hamburg so läuft. Auch das gehört
                bei uns zur Einarbeitung.
              </p>
              <h3>Urlaubsreif?</h3>
              <p>
                Jeder Anfang ist aufregend und du wirst im UKE von vielen neuen
                Eindrücken geflutet. Das muss man erstmal verarbeiten. Damit
                dabei die Erholung nicht zu kurz kommt, kannst du bei uns schon
                während der sechsmonatigen Probezeit anteilig Urlaub nehmen.
              </p>
              <h3>Du willst mehr?</h3>
              <p>
                In unseren Bereichen finden regelmäßige, interne Fortbildungen
                statt; die Teilnahme an externen Angeboten ist ebenfalls
                möglich. Unsere{' '}
                <a href="https://www.uke.de/organisationsstruktur/zentrale-bereiche/uke-akademie-fuer-bildung-karriere/index.html">
                  Akademie für Bildung & Karriere (ABK)
                </a>{' '}
                bietet dir zudem vielfältige Möglichkeiten der Fort- und
                Weiterbildung.
              </p>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mtra/BSS_MTRA_Bild_6.jpg"
                    alt="BSS MTRA Bild 6"
                    title="BSS MTRA Bild 6"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Mach dich warm für den Start!</h2>
              <p>
                Stöbere hier durch die Bereiche, in denen du als MTR/MTRA
                gefragt bist.
              </p>
            </div>
          </div>
        </section>

        <StationsShortlist area={AREA_MTRA} stations={pageContext.stations} />

        <section id="stuermen" className="bg-img-green divider-right">
          <div className="row align-items-center text-center">
            <div className="col-md-3 offset-md-1 col-12">
              <img
                src="/img/illustration-pfleger-stuermen.png"
                alt="Illustration: Pfleger einzeln"
                height="311"
                width="145"
              />
            </div>
            <div className="col-md-4 offset-md-0 col-10 offset-1">
              <img
                src="/img/handlettering/handlettering-ich-bin-mtr-lasst-mich-durch.png"
                alt="Handlettering: Ich bin MTRA lasst mich durch!"
                width="400"
              />
            </div>
            <div className="col-md-3 col-12">
              <h4>
                Du weißt, was du willst:
                <br />
                Bewirb dich direkt!
              </h4>
              <Button
                color="green"
                href="mailto:bewerbung@uke.de?subject=MTRA-Initiativbewerbung über die jukebox"
              >
                Stürmen
              </Button>
            </div>
          </div>
        </section>

        <section>
          <figure>
            <div className="row align-items-center">
              <div className="col-sm-5 offset-sm-1 col-12 order-sm-1 order-1 text-center">
                <img src="img/mtra/rita-herbrand.jpg" />
              </div>
              <div className="col-sm-5 col-12 order-sm-2 order-3">
                <ul className="contact-channels">
                  <li className="phone">
                    <a href="tel:+4940741054551">+49 (0) 40 74 10 - 545 51</a>
                  </li>
                  {/*<li className="mobile"><a href="tel:+4915222">0152 22 82 </a></li>*/}
                  <li className="email">
                    <a href="mailto:herbrand@uke.de">herbrand@uke.de</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-5 offset-sm-1 col-12 order-sm-3 order-2">
                <figcaption>
                  Rita Herbrand
                  <br />
                  Medizinisch-technische Zentrumsleitung
                </figcaption>
              </div>
            </div>
          </figure>
        </section>

        <StaticReferenceModules referencesData={areaPageReferences} />

        <Footer />
      </main>
    </CMSWrapper>
  )
}

export const query = graphql`
  query MtraReferencesQuery($referenceType: [String]) {
    allReference(
      filter: { types: { in: $referenceType }, isDraft: { eq: false } }
    ) {
      nodes {
        _id
        content {
          sections {
            _template
            bgColor
            grids {
              _template
              columns {
                _template
                altText
                description
                descriptionAlignment
                showFrame
                filename
                listStyle
                text
                textAlign
                url
                images {
                  _template
                  altText
                  description
                  filename
                  showFrame
                }
              }
              itemAlignment
              marginType
            }
          }
        }
        mediaDir
        priority
        slug
        title
      }
    }
  }
`
